@import 'styles/utils';

.modalDetailedContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: $white;
  width: 94%;
  max-width: calc(1410px * 0.94);
  border-radius: 4px;
  padding: 20px 20px 0;
  max-height: 600px;
  overflow: auto;
  @include breakpoint('md') {
    padding: 30px 30px 10px;
  }
}

.headline.headline {
  font-size: 32px;
  font-weight: 900;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.detailedText.detailedText p {
  font-size: 16px;
  line-height: 24px;
  color: $grey-80;
  margin: 0;
}

.buttonWrapper {
  display: flex;
  row-gap: 20px;
  margin: 30px 0 20px;
  flex-direction: column;
  @include breakpoint('sm') {
    flex-direction: row;
    column-gap: 20px;
  }
}

.goBackButton {
  border: none;
  padding: 0;
  background-color: inherit;
  margin-bottom: 50px;
  .goBackButtonText.goBackButtonText {
    line-height: 24px;
    color: $grey-80;
    text-decoration: underline;
    cursor: pointer;
  }
}
