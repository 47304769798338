@import 'styles/utils';

.menuItem {
  padding-bottom: 15px;
  &:last-of-type {
    padding-bottom: 0;
  }
}

.link {
  color: inherit;
  text-decoration: none;
  &:hover {
    @include color-opacity;
  }
}

.icon {
  height: 24px;
  width: 24px;
}

.loginModalButton {
  cursor: pointer;
  margin-right: 20px;
  color: $white;
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 0;
  background-color: inherit;
  @include breakpoint('sm') {
    margin-right: 40px;
  }
  @include breakpoint('lg') {
    margin-right: 20px;
  }
  @include breakpoint('xl') {
    margin-right: 40px;
  }
  &:hover {
    @include color-opacity;
  }
  .loginModalButtonText.loginModalButtonText {
    display: none;
    @include breakpoint('lg') {
      display: block;
      margin-left: 10px;
    }
  }
}

.dropDownLoginWrapper {
  position: relative;
  display: flex;
  margin-right: 20px;
  @include breakpoint('sm') {
    margin-right: 40px;
  }
  @include breakpoint('lg') {
    margin-right: 20px;
  }
  @include breakpoint('xl') {
    margin-right: 40px;
  }
}

.loggedInMenuButton {
  color: $white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: inherit;
  padding: 0;
  &:hover {
    @include color-opacity;
  }
  .loggedInMenuButtonText.loggedInMenuButtonText {
    display: none;
    @include breakpoint('lg') {
      margin-left: 6px;
      display: block;
    }
  }
}

.dropDownWrapper {
  position: absolute;
  top: 42px;
  right: 0;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 220px;
  .ul {
    list-style: none;
    padding: 15px 18px;
    margin: 0;
    color: $dark-Grey;
  }
}

.dropDownMenuButton {
  color: $white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
  border: none;
  padding: 0;
  &:hover {
    @include color-opacity;
  }
  .dropDownMenuButtonText.dropDownMenuButtonText {
    display: none;
    @include breakpoint('lg') {
      display: block;
      margin-left: 6px;
    }
  }
}

.dropDownMenuWrapper {
  position: absolute;
  top: 42px;
  right: 0;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 206px;
  .ul {
    list-style: none;
    padding: 15px 18px;
    margin: 0;
    color: $dark-Grey;
  }
}
