@import 'styles/utils';

.button.button {
  padding: 5px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 7px;
}

.categoryIcon {
  padding-right: 5px;
  height: 24px;
}