@import 'styles/utils';
$width: 100vw;
$full-width: 100vw;
$width: 42rem;
@media (min-width: 42em) {
  $width: 42rem;
}
$angle: -1.3deg;
$magic-number: 0.017460384745873865;
$abs-angle: max($angle, $angle * -1);
$tan-alpha: tan($abs-angle);
$skew-padding: calc($width * $tan-alpha / 2);
$clip-padding: calc($full-width * $tan-alpha / 2);
.topDiagonal {
  &.diagonal.bottom,
  &:not(.diagonal) {
    margin-top: calc(($clip-padding * -2));
  }
}
.anchorLink {
  position: relative;
  span {
    position: absolute;
    top: -65px;
  }
}
.bottom .content {
  padding-bottom: calc(($clip-padding * 2));
}
.topPadding {
  padding-top: 10px;
  @include breakpoint('lg') {
    padding-top: 50px;
  }
}
.botPadding {
  padding-bottom: 10px;
  @include breakpoint('lg') {
    padding-bottom: 50px;
  }
}
.content {
  & > *:not(.absolute) {
    margin: 0 3%;
  }
  margin: 0 auto;
  position: relative !important;
}
.diagonal:not(.top):not(.bottom) {
  // SKEWY variant

  // position: relative;
  // padding: $skew-padding 0;
  // margin-top: -1px;
  // background-color: inherit;
  // z-index: 10;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   transform: skewy($angle);
  //   transform-origin: 50% 0;
  //   outline: 1px solid transparent;
  //   backface-visibility: hidden;
  //   // background-color: red;
  // }

  // CLIP PATH VARIANT
  position: relative;
  margin-top: calc(($clip-padding * -2));

  // background-size: 100% auto, 100% 100%;
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: calc(($clip-padding * 2) - ($clip-padding - $skew-padding)) 0;
  z-index: 1;
  clip-path: polygon(
    0% calc($clip-padding * 2),
    100% 0%,
    100% calc(100% - ($clip-padding * 2)),
    0% 100%
  );

  -webkit-clip-path: polygon(
    0% calc($clip-padding * 2),
    100% 0%,
    100% calc(100% - ($clip-padding * 2)),
    0% 100%
  );
}
.top,
.bottom,
.diagonal {
  .innerBackground {
    margin-top: calc(($clip-padding * -3));
    padding-top: calc(($clip-padding * 3));
    margin-bottom: calc(($clip-padding * -5));
    padding-bottom: calc(($clip-padding * 3));
  }
}
.innerBackground {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    @include breakpoint('md') {
      width: calc(300vw + 300vh);
      height: calc(300vw + 300vh);
      left: -100%;
      top: -140%;
    }
    width: 300vh;
    height: 400%;
    top: -180%;
    left: -50%;
    z-index: -1;
    background-position: 0 0;
    background-repeat: repeat;
    // background: url(https://minio.starcar.de:443/strapi/SC_Pattern_1x_1_582fba7008.png) 0 0 repeat;
  }
}
.bottom {
  z-index: 1;
  position: relative;
  margin-bottom: calc(($clip-padding * -2) - 2px);
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: 2em 0 calc(($clip-padding * 2) - ($clip-padding + $skew-padding));
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - ($clip-padding * 2)), 0% 100%);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - ($clip-padding * 2)), 0% 100%);
}

.top {
  z-index: 1;
  position: relative;
  margin-top: calc(($clip-padding * -1 * 2) - 2px);
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: calc(($clip-padding * 2) - ($clip-padding - $skew-padding)) 0;
  padding-bottom: 0;
  clip-path: polygon(0% calc($clip-padding * 2), 100% 0%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0% calc($clip-padding * 2), 100% 0%, 100% 100%, 0% 100%);
}
.content .absolute {
  position: absolute;
  width: 100%;

  // padding-top:  calc(($clip-padding  * 2));
  transform: translateY(-30%);
  z-index: 50;
}
.maxWidth {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
}

.fullWidth {
  width: 100%;
}
