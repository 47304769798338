@import 'styles/utils';

.forgotPassword.forgotPassword {
  display: block;
  margin: 10px auto;

  text-align: center;
  width: fit-content;
  text-decoration: underline;
  cursor: pointer;
}

.textField {
  width: 100%;
}

.buttonWrapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.button {
  width: 100%;
  padding: 18px 20px;
}

.headline.headline {
  line-height: 2rem;
  margin-bottom: 25px;
  display: none;
  @include breakpoint('md') {
    display: block;
  }
}
