@import 'styles/utils';

.textField {
  width: 100%;
  margin-bottom: 1rem;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 150px;
  max-height: 100%;
  @include breakpoint('md') {
    height: 100%;
    position: absolute;
  }
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: $white;
  width: 94%;
  max-width: calc(1410px * 0.78);
  border-radius: 4px;
  @include breakpoint('xl') {
    width: 78%;
  }
  &:focus-visible,
  &:focus {
    outline: none 0;
  }
}

.closingIconWrapper {
  position: absolute;
  right: 0;
  top: -30px;
  height: 24px;
  width: 24px;
  background-color: inherit;
  padding: 0;
  display: grid;
  place-content: center;
  cursor: pointer;
  color: $white;
  z-index: 1;
  border: none;
}

.icon {
  height: 24px;
  width: 24px;
}

.formWrapper {
  padding: 20px 3% 20px 3%;
  @include breakpoint('md') {
    padding: 20px 20px 25px 20px;
  }
  @include breakpoint('xxl') {
    padding: 25px 25px 20px 25px;
  }
}

.innerFormWrapper {
  width: 100%;
  @include breakpoint('xxl') {
    width: 430px;
  }
}

.registrationWrapper {
  padding: 40px 3% 20px 3%;
  margin-top: auto;
  background-color: $light-Grey;
  @include breakpoint('md') {
    padding: 15px 20px 10px 20px;
  }
  @include breakpoint('xxl') {
    padding: 20px 25px 30px 25px;
  }
}

.registerButton.registerButton {
  width: 100%;
  margin-top: 10px;
  @include breakpoint('xs') {
    margin-top: 34px;
  }
  @include breakpoint('md') {
    margin-top: 10px;
  }
}

.mobileTitle.mobileTitle {
  position: absolute;
  bottom: 20px;
  left: 3%;
  display: block;
  color: $white;
  line-height: 32px;
  @include breakpoint('md') {
    display: none;
  }
}

.headline.headline {
  line-height: 2rem;
  margin-bottom: 25px;
  display: none;
  @include breakpoint('md') {
    display: block;
  }
}
