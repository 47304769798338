@import 'styles/utils';

.bg-lightgray {
  background-color: #f3f3f3;
}
.headline {
  text-transform: uppercase;
  font-weight: 700;
  line-height: normal;
}
.wrapperStyles {
  ol {
    li:has(span.h2) {
      font-weight: bold;
    }
    li {
      list-style-type: inherit;
    }
    list-style-type: upper-latin;
    ol {
      list-style-type: upper-roman;
      ol {
        list-style-type: decimal;
        ol {
          list-style-type: decimal;
        }
      }
    }
  }
  li {
    list-style-type: square;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  ul:has(svg) {
    padding-inline-start: 20px;
  }
}
.boldMarker {
  &::marker {
    font-weight: bold;
  }
}

.checkmark {
  list-style-type: none !important;
  display: flex;
}

.sectiontitle.sectiontitle {
  margin-bottom: 10px;
  font-weight: 700;
}
// .bg,
// .bg-headline,
// .bg2,
// .bg3 {
//     padding: 30px;
//     position: relative;
// }

.h1.h1 {
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 20px;
  @include breakpoint('md') {
    font-size: 42px;
  }
}

.h2.h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  @include breakpoint('md') {
    font-size: 32px;
  }
}

.h3.h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  @include breakpoint('md') {
    font-size: 24px;
  }
}

.h4.h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  @include breakpoint('md') {
    font-size: 20px;
  }
}

.h5.h5 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  @include breakpoint('md') {
    font-size: 16px;
  }
}
