@import 'styles/utils';

.cookieParameter {
  font-weight: 700;
  line-height: 24px;
  color: $grey-80;
}

.cookieValues {
  line-height: 24px;
  color: $grey-80;
}

.tableMarkdown {
  & p {
    line-height: 24px;
    color: $grey-80;
    margin: 0;
  }
}
