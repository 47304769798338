@import 'styles/utils';

.cookieCheckbox {
  width: 100%;
  position: relative;
  border-bottom: 1px solid $grey-10;
  margin-bottom: 20px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $black;
    margin-bottom: 16px;
  }
}

.icon {
  height: 24px;
  width: 24px;
  color: $black;
}

.tableWrapper {
  position: relative;
  margin-top: 50px;
  width: 100%;
  overflow-y: auto;
}

.tableWrapper td {
  vertical-align: baseline;
}

.tableColumn1 {
  width: 15%;
  @include breakpoint('sm') {
    width: 30%;
  }
}

.tableColumn2 {
  width: 85%;
  @include breakpoint('sm') {
    width: 70%;
  }
}

.button {
  padding: 0;
  border: none;
  background-color: inherit;
  display: block;
  margin: 20px auto;
  .buttonText.buttonText {
    display: block;
    line-height: 24px;
    color: $grey-80;
    text-decoration: underline;
    cursor: pointer;
  }
}
