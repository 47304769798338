@import 'styles/utils';

.checkbox.checkbox {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  & * {
    color: $black;
  }
}

.icon {
  height: 24px;
  width: 24px;
  color: $black;
}

.cookieParameter {
  font-weight: 700;
  line-height: 24px;
  color: $grey-80;
}

.cookieValues {
  line-height: 24px;
  color: $grey-80;
}

.spacer {
  height: 50px;
}
