@import 'styles/utils';

.wrapper {
  position: relative;
  display: flex;
}

.wrapperMargin {
  margin-right: 20px;
  @include breakpoint('sm') {
    margin-right: 40px;
  }
  @include breakpoint('lg') {
    margin-right: 20px;
  }
  @include breakpoint('xl') {
    margin-right: 40px;
  }
  @include breakpoint('xxl') {
    margin-right: 35px;
  }
}

.flag {
  color: $white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
  border: none;
  padding: 0;
  &:hover {
    @include color-opacity;
  }
}

.dropDown {
  position: absolute;
  top: 42px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $grey-50;
  z-index: 1;
  width: 130px;
  @include breakpoint('sm') {
    width: 175px;
  }
  &.dropDownRight {
    right: 0;
  }
}

.ul {
  list-style: none;
  padding: 15px 18px;
  margin: 0;
  height: 100%;
  color: $dark-Grey;
}

.link {
  color: inherit;
  text-decoration: none;
  &:hover {
    @include color-opacity;
  }
}

.menuItem {
  padding-bottom: 15px;
  &:last-of-type {
    padding-bottom: 0;
  }
}
