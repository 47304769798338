@import 'styles/utils';

.contentWrapper {
  margin: 0 3%;
  height: 65px;
  display: flex;
  align-items: center;
}

.linkWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include breakpoint('sm') {
    justify-content: space-between;
  }
}

.linkText.linkText {
  color: $white;
  margin-right: 0;
  @include breakpoint('sm') {
    margin-right: 20px;
  }
  @include breakpoint('xl') {
    margin-right: 40px;
  }
  &:hover {
    @include color-opacity;
  }
  &:last-of-type {
    margin-right: 0;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
}
