@import 'styles/utils';

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: $white;
  width: 94%;
  max-width: calc(1410px * 0.94);
  border-radius: 4px;
  padding: 20px 20px 30px;
  max-height: 80vh;
  overflow: auto;
  @include breakpoint('xxl') {
    padding: 30px;
  }
}

.headline.headline {
  color: #1a1a1a;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 900;
  @include breakpoint('md') {
    font-size: 32px;
  }
}

.markdown {
  & p {
    color: $grey-80;
    font-size: 14px;
    line-height: 16px;
    @include breakpoint('md') {
      font-size: 16px;
      line-height: 24px;
    }
  }
  margin-bottom: 20px;
  @include breakpoint('xl') {
    margin-bottom: 30px;
  }
}

.checkboxWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  @include breakpoint('xl') {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.icon {
  height: 24px;
  width: 24px;
}

.disabledLabel.disabledLabel {
  margin: 0;
  align-items: center;
  width: 50%;
  @include breakpoint('sm') {
    width: 33.33%;
  }
  & span:last-of-type {
    color: $black !important;
  }
}

.label.label {
  margin: 0;
  align-items: center;
  width: 50%;
  @include breakpoint('sm') {
    width: 33.33%;
  }
}

.checkbox {
  padding: 0 10px 0 0;
  & * {
    color: $black;
  }
}

.detailedModalButtonWrapper {
  padding: 15px 0;
  border-bottom: 1px solid $grey-10;
  display: flex;
  justify-content: center;
  @include breakpoint('xl') {
    justify-content: flex-start;
  }
  .detailedModalButton {
    border: none;
    padding: 0;
    background-color: inherit;
  }
  .buttonText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-80;
    text-decoration: underline;
    cursor: pointer;
  }
}

.linkButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  @include breakpoint('xl') {
    flex-direction: row;
  }
  @include breakpoint('xxl') {
    margin-top: 30px;
  }
  .linkWrapper {
    display: flex;
    gap: 27px;
  }
  .linkText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-80;
    & a {
      text-decoration: underline;
      color: inherit;
    }
  }
}

.buttonWrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  @include breakpoint('xl') {
    flex-direction: row;
    gap: 20px;
    width: auto;
    margin-top: 0;
  }
  .button {
    width: 100%;
    @include breakpoint('xl') {
      width: auto;
    }
  }
}
