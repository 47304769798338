/* Style for the grid overlay */
.gridOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.container {
  max-width: 1410px;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.column {
  width: 6%;
  height: 100%;
  margin: 0 1%;
  background-color: darkorange;
  opacity: 0.3;
}

.column:first-of-type {
  margin-left: 3%;
}

.column:last-of-type {
  margin-right: 3%;
}
