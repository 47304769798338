@import 'styles/utils';

.openedBPR {
  margin: 0 3%;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.expanded {
  max-height: fit-content;
  transition: max-height 0.5s ease-in;
}

.stationTextOneWay {
  font-weight: 700;
  line-height: 24px;
  color: $grey-80;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 155px;
}

.oneWayDistanceBox {
  height: 24px;
  margin-top: -2px;
  line-height: 24px;
  color: $mid-Grey;
}